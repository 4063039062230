import React from 'react'

import logo from '../asset/images/shared/header/logo.png'
import { LocalPhone,Instagram,Facebook,Twitter,LocationOn } from '@mui/icons-material'

function Header() {
    return (
        <header className='bg-primary  w-full px-[10%] text-white p-3 space-y-3'>
            <div className=" w-full flex justify-between items-center">
                <div className="flex gap-2  items-center">
                    <a href="https://www.facebook.com/#"> <Facebook style={{fontSize:'1rem'}} /> </a>
                    <a href="https://cambridgepuntingtours.com/#"> <Twitter style={{fontSize:'1rem'}} /> </a>
                    <a href="https://www.instagram.com/#"> <Instagram style={{fontSize:'1rem'}} /> </a>
                    <a href="https://goo.gl/maps/SGiDWmZpX42hcUD3A"> <LocationOn style={{fontSize:'1rem'}} /> </a>
                </div>
                <div className='text-xs flex items-center gap-2'>
                    <LocalPhone style={{ fontSize: '1rem' }} />
                    <p className='font-medium'> 01223 782503 </p>
                </div>
            </div>
            <div className="w-full h-[1px] bg-black"></div>
            <div className="w-full  flex justify-center md:justify-between items-center ">
                <img src={logo} alt="logo" className='w-40 h-[75px]' />
                <ul className='hidden md:flex items-center gap-9 font-medium'>
                    <li className='cursor-pointer'><a href="https://cambridgepuntingtours.com/"> HOME </a></li>
                    <li className='cursor-pointer'><a href="https://cambridgepuntingtours.com/about-us/"> ABOUT US </a></li>
                    <li className='cursor-pointer'><a href="https://cambridgepuntingtours.com/product-category/tour-list/"> TOURS </a></li>
                    <li className='cursor-pointer'><a href="https://cambridgepuntingtours.com/faqs/"> FAQS </a></li>
                    <li className='cursor-pointer'><a href="https://cambridgepuntingtours.com/blog/"> BLOG </a></li>
                    <li className='cursor-pointer'><a href="https://cambridgepuntingtours.com/contact-us/"> CONTACT US </a></li>
                </ul>
            </div>

        </header>
    )
}

export default Header