import React from 'react'

import logo from '../asset/images/shared/header/logo.png'
import {LocationOn,LocalPhone,Email,Facebook,Instagram,Twitter} from '@mui/icons-material'

function Footer() {
  return (
    <div className=' bg-primary text-white space-y-3'>
      <div className="flex justify-between items-center flex-col lg:flex-row gap-5 px-[10%] py-[2%]">
        
        <div className=" flex flex-col items-center lg:items-start gap-5">
          <img src={logo} alt="logo" className='w-40 h-[75px]' />
          
        </div>

        <div className=" space-y-5">
          <div className="flex gap-2 items-start justify-start">
            <LocationOn className='relative top-1'  style={{fontSize:'1rem'}}/>
            <p>La Mimosa, <br/>
              1 Thompsons Ln, <br/>
              Cambridge CB5 8AQ</p>
          </div>

          <div className="flex gap-2 items-start justify-start">
            <LocalPhone className='relative top-1'  style={{fontSize:'1rem'}}/>
            <p>01223 782503</p>
          </div>

          <div className="flex gap-2 items-start justify-start">
            <Email className='relative top-1'  style={{fontSize:'1rem'}}/>
            <p>info@cambridgepuntingtours.com</p>
          </div>

          <div className="flex gap-2 justify-center lg:justify-start items-center">
            <Facebook/>
            <Instagram />
            <Twitter />
          </div>


        </div>
        
        <div className="flex flex-col gap-5">
          <p>Working Days: 9AM - 5PM</p>
          <p>Working Hours: Monday to Sunday</p>

         <a href="https://cambridgepuntingtours.com/product-category/tour-list/"> <button className='w-full h-12 border border-white'>BOOK NOW</button></a>
        </div>
      </div>
      <div className="w-full h-[1px] bg-white"></div>
      <div className="flex flex-col justify-center md:flex-row  md:justify-between items-center text-sm gap-5 px-[10%]  py-[1%]">
        <p>© Cambridge Punting Tours 2023. All Rights Reserved</p>
        <div className="space-x-5">
          <a href="https://cambridgepuntingtours.com/terms-conditions/"><button>Terms & Conditions </button></a>
          <a href="https://cambridgepuntingtours.com/privacy-policy/"><button>Privacy Policy</button></a>
        </div>
      </div>
    </div>
  )
}

export default Footer