import { useSelector } from "react-redux"

const Details = () => {
    const { type, group, date, price } = useSelector(state => state.data)
    return (
        <div className="w-full  bg-white mt-5 p-3 pl-5 flex flex-col justify-center">
            <div className="flex font-bold"> <p className='w-28'>Tour:</p> <p>Punting</p> </div>
            <div className="flex text-sm"> <p className='w-28'>Type:</p> <p>{type}</p> </div>
            <div className="flex text-sm"> <p className='w-28'>Group Size:</p> <p>Ticket - Valid for up to {group}</p> </div>
            <div className="flex text-sm"> <p className='w-28'>Booking Date:</p> <p>{date.toString()}</p> </div>
            <div className="flex text-sm"> <p className='w-28'>Total:</p> <p>£ {price}</p> </div>
        </div>
    )
}

export default Details