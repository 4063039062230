import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name:'',
    email:'',
    mobileNo:''
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setName(state,{payload}){state.name=payload},
    setEmail(state,{payload}){state.email=payload},
    setMobileNo(state,{payload}){state.mobileNo=payload},
  }
});

export const {setName,setEmail,setMobileNo} = user.actions

export default user.reducer