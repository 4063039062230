import { createBrowserRouter, createRoutesFromElements,  Route } from "react-router-dom";
import Checkout from "./pages/checkout";
import Home from "./pages/home";
import Payment from "./pages/payment";
import ThankYou from "./pages/thankyou";

import Root from "./shared/Root";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={ <Root/> } >
            <Route index element={<Home/>} />
            <Route path="/checkout" element={<Checkout/>} />
            <Route path="/payment" element={<Payment/>} />
            <Route path="/thank-you" element={<ThankYou/>} />
        </Route>
    )
)

export default router

