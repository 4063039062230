const EveningPrice = (month, group, day, date) => {
    if(
        (day==='Sat') ||
        (month==='August' && date === 28) ||
        (month==='December' && date === 25) || 
        (month==='December' && date === 26) ) return 250
    if(day==='Sun') return 200
    return 130
}

export default EveningPrice