import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setEmail, setMobileNo, setName } from '../../redux/slice/user'
import Details from '../../shared/Details'
import bgImage from '../../asset/images/background image/banner.jpeg'
import axios from 'axios'
import backendIP from '../../BackendIP'


function Checkout() {

    const [agree, setAgree] = useState(false)
    const dispatch = useDispatch()
    const { name, email, mobileNo } = useSelector(state => state.user)
    const { price,date,type,group,boat } = useSelector(state => state.data)
    const naviagte = useNavigate()
    
    return (
        <div className='min-h-screen w-full flex justify-center items-center bg-no-repeat bg-cover  bg-bottom py-5' style={{backgroundImage:`url(${bgImage})`}}>
            <div className="w-[640px]  p-2 bg-primary">
                <p className='text-lg font-medium text-center text-white '>Checkout</p>
                <div className="space-y-3">
                    <Details />
                    <div className="w-full  bg-white">

                         
                            <div className="p-5 space-y-5">

                                <div className="">
                                    <p>Name</p>
                                    <input type="text" className='w-full h-9 outline-none border rounded-lg pl-5' value={name} onChange={e => dispatch(setName(e.target.value))} />
                                </div>

                                <div className="">
                                    <p>Email Address</p>
                                    <input type="email" className='w-full h-9 outline-none border rounded-lg pl-5' value={email} onChange={e => dispatch(setEmail(e.target.value))} />
                                </div>

                                <div className="">
                                    <p>Mobile Number</p>
                                    <input type="tel" className='w-full h-9 outline-none border rounded-lg pl-5' value={mobileNo} onChange={e => dispatch(setMobileNo(e.target.value))} />
                                </div>

                                <div className="">
                                    <p>Date</p>
                                    <input className='w-full h-9 outline-none border rounded-lg pl-5' value={date} readOnly />
                                </div>

                                <div className="">
                                    <p>Type</p>
                                    <input className='w-full h-9 outline-none border rounded-lg pl-5' value={type} readOnly />
                                </div>
                                
                                <div className="">
                                    <p>Group</p>
                                    <input className='w-full h-9 outline-none border rounded-lg pl-5' value={`Valid for up to ${group}`} readOnly />
                                </div>

                                <div className="">
                                    <p>No of boat</p>
                                    <input className='w-full h-9 outline-none border rounded-lg pl-5' value={boat} readOnly />
                                </div>
                                
                                <div className="">
                                    <p>Price</p>
                                    <input className='w-full h-9 outline-none border rounded-lg pl-5' value={price} readOnly />
                                </div>
                                
                                <p>Thank You for ordering, your confirmation notice will be with you in the next two hours</p>

                                <p>Please double check you have entered the correct mobile number above as we use this to send your departure text message</p>

                                <div className="flex gap-5">
                                    <input checked={agree} onChange={e => setAgree(e.target.checked)} type="checkbox" name="" id="" />
                                    <p>I agree the terms and conditions.</p>
                                </div>

                            </div>

                        
                        <div className="flex justify-between items-center p-5">
                            <Link to={'/'} className='h-12 w-[30%]'>
                                <button className='h-12 w-full border-primary border text-primary'>Restart</button>
                            </Link>
                            <button disabled={!agree} className={`h-12 w-[65%] ${agree ? 'bg-primary' : 'bg-primary/30'} bg-primary text-white`} onClick={() => {
                                if (agree && name && email && mobileNo) {
                                    axios.get(`${backendIP}/tours/slot/${date}`).then(res=>{
                                        if(res.data?.filter(e=>e.email === email)?.length ===2){
                                            window.alert('You are already Booked this slot 2 times')
                                        }else{
                                            naviagte('/payment')
                                        }
                                    })
                                } else {
                                    window.alert("Please fill all the coloum ")
                                }
                            }}>BOOK NOW</button>
                        </div>


                    </div>



                </div>
            </div>
        </div>
    )
}

export default Checkout



