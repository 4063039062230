import { createSlice } from '@reduxjs/toolkit'
import SharedPrice from './price calculation/sharedPrice';
import PrivatePrice from './price calculation/privatePrice';
import EveningPrice from './price calculation/eveningPrice';
import HenPartyPrice from './price calculation/henPartyPrice';


const date = new Date()

const initialState = {
    type:'Shared',
    group:'2 People',
    date,
    boat:1,
    price:35
}

const data = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setType(state,{payload}){state.type=payload},
    setGroup(state,{payload}){state.group=payload},
    setBoat(state,{payload}){state.boat=payload},
    
    setDate(state,{payload}){state.date = payload},

    setTime(state,{payload}){
        state.date.setHours(payload.hour)
        state.date.setMinutes(payload.minute)
    },
    setPrice(state,{payload}){
        const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
        
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        const day = days[state.date.getDay()]
        const date = state.date.getDate()
        const month = months[state.date.getMonth()]

        if(state.type==='Shared'){
            state.price = SharedPrice(month,state.group,day,date)
        }
        else if(state.type==='Private'){
            state.price=PrivatePrice(month,state.group,day,date)
        }
        else if(state.type==='Hen Party'){
           state.price = HenPartyPrice(month,state.group,day,date)
        }
        else if(state.type==='Evening'){
            state.price = EveningPrice(month,state.group,day,date)
        }
        state.price = state.price * state.boat
    }
  }
});

export const {setType,setGroup,setDate,setTime,setPrice,setBoat} = data.actions

export default data.reducer