import { configureStore } from "@reduxjs/toolkit";
import data from "./slice/data";
import user from "./slice/user";

const store = configureStore({
    reducer:{
        data,
        user

    }
})


export default store