import React from 'react'

function ThankYou() {
  return (
    <div className='h-screen w-full flex justify-center items-center text-lg'>
      <p className='text-center'>
      Thank you for ordering punting in Cambridge. <br />
      We appreciate your trust and look forward to providing an unforgettable experience. <br />
       Our team is excited to accompany you along the River Cam, sharing stories and creating a relaxing journey. <br />
        For further assistance, contact us at <a href="tel:+44 7770 515385">+44 7770 515385</a>.
      </p>
      </div>
  )
}

export default ThankYou